<template>
    <div></div>
</template>

<script>
import {VERIFY_EMAIL_URL} from '@/config/api'
import {guest_route} from '@/config/auth';

export default {
    name: "EmailVerification",
    middleware: 'guest',
    async beforeCreate() {
        let url = VERIFY_EMAIL_URL
            .replace(':hash', this.$route.params.hash)
            .replace(':id', this.$route.params.id);
        const query = new URLSearchParams(this.$route.query);

        url += `?${query.toString()}`;

        const {data, status} = await this.$http.get(url);

        if(status === 200){
            this.$store.dispatch('serverNotice/setNotice', data);
        }
        this.$router.push(`/${guest_route}`);
    }
}
</script>

<style scoped>

</style>